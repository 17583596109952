import React, { FunctionComponent } from 'react'
import './tooltip.scss'
import { formatTime } from '../ChartUtil'
import { Trade } from '../../../classes/Trade'
import { observer } from 'mobx-react'
import { useStores } from '../../../useStores'
import { Area } from '../../../classes/QueryOptions'

const formatKW = (amount?: number) => (amount ? `${amount / 1000} MW` : '')
const formatCent = (amonut?: number) =>
  amonut ? `${(amonut / 100).toFixed(2)} €/MWh` : ''

const formatArea = (areas: Area[], areaId?: string) => {
  if (areaId) {
    const area = areas.find((a) => a.queryId === areaId)
    return area ? area.name : areaId
  }
  return ''
}

export type TooltipProps = {
  show: boolean
  x: number
  y: number
  trade?: Trade
}

/**
 * Shows trade corresponding to a circle
 */
const Tooltip: FunctionComponent<TooltipProps> = observer((props) => {
  const { initializerStore } = useStores()
  const { getAreas } = initializerStore
  const { show, x, y, trade } = props

  return (
    <table
      className="tooltip"
      style={{
        display: !show ? 'none' : '',
        position: 'absolute',
        left: x,
        top: y,
        zIndex: 100,
      }}
    >
      <tbody style={{ whiteSpace: 'nowrap' }}>
        <tr>
          <td>Time</td>
          <td>{trade?.time ? formatTime(trade.time, true) : ''}</td>
        </tr>
        <tr>
          <td>Volume</td>
          <td>{formatKW(trade?.quantity)}</td>
        </tr>
        <tr>
          <td>Price</td>
          <td>{formatCent(trade?.price)}</td>
        </tr>
        <tr>
          <td>Sell area</td>
          <td>{formatArea(getAreas, trade?.sellArea)}</td>
        </tr>
        <tr>
          <td>Buy area</td>
          <td>{formatArea(getAreas, trade?.buyArea)}</td>
        </tr>
      </tbody>
    </table>
  )
})

export default Tooltip
