import {
  Fragment,
  FunctionComponent,
  MutableRefObject,
  useEffect,
  useState,
} from 'react'
import './graph-setting.scss'
import Select from '../Select/Select'
import { colors, dateFormat, ranges } from '../../Util'
import Button from '../Button/Button'
import { observer } from 'mobx-react'
import { useStores } from '../../useStores'
import DatePicker from '../DatePicker/DatePicker'
import moment from 'moment'
import IndexPicker from '../IndexPicker/IndexPicker'
import axiosInstance from '../../axios'
import { Contract, TradeQuery } from '../../classes/QueryOptions'
import { getIndicatorName, indicatorOptions } from '../Graph/ChartUtil'
import Input from '../Input/Input'
import Toggle from '../Toggle/Toggle'
import ColorPicker from '../ColorPicker/ColorPicker'
import { Indicator, SMA, VWAP } from '../../classes/Indicator'
import { ChartInfo } from '../../classes/ChartInfo'
import { MinMaxProps } from '../Graph/Graph'
import { toast } from 'react-toastify'
import { Trade } from '../../classes/Trade'

type GraphSettingProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reference: MutableRefObject<any>
  exit: () => void
  chartState: ChartInfo
  setChartState: (c: ChartInfo) => void
  query: TradeQuery
  setQuery: (t: TradeQuery) => void
  area: number
  product: number
  productType: number
  minMax: MinMaxProps
  setMinMax: (m: MinMaxProps) => void
  indicators: Indicator[]
  setIndicators: (indicators: Indicator[]) => void
  tradeLine: boolean
  toggleTradeLine: () => void
  direction?: DirectionProps
  happyHour?: boolean
  setHappyHour?: (happyHour: boolean) => void
  lastTrade?: Trade
}

function formatTime(date: Date) {
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
}

type ButtonProp = { fun: () => void }
const SettingsButton: FunctionComponent<ButtonProp> = ({ fun }) => (
  <div className="icon settings-show" onClick={fun} title={'Settings'}>
    &#9788;
  </div>
)

const ExitButton: FunctionComponent<ButtonProp> = ({ fun }) => (
  <div className="icon exit-button" onClick={fun} title={'Close'}>
    &#9587;
  </div>
)

const IndicatorButton: FunctionComponent<ButtonProp> = ({ fun }) => (
  <div className="icon indicator-button" onClick={fun} title={'Indicators'}>
    &#x301C;
  </div>
)

export enum Direction {
  UP,
  DOWN,
  NEU,
}

type DirectionProps = { direction: Direction; price: number }

const DirectionIndicator = ({ direction }: { direction?: DirectionProps }) => {
  if (!direction) return null

  let icon
  if (direction.direction === Direction.NEU)
    icon = <div style={{ color: 'white' }}>&#x2212;</div>
  else if (direction.direction === Direction.UP)
    icon = <div style={{ color: 'green' }}>&#x25B2;</div>
  else icon = <div style={{ color: 'red' }}>&#x25BC;</div>

  return (
    <div className="direction-indicator">
      {icon}
      <div className="price">{direction.price / 100}</div>
    </div>
  )
}

const LastTradeTimeIndicator = ({ lastTrade }: { lastTrade?: Trade }) => {
  return (
    <div className="last-trade-time-indicator">
      {lastTrade && formatTime(lastTrade.time)}
    </div>
  )
}

const HappyHourIndicator = ({
  setHappyHour,
}: {
  setHappyHour?: (happyHour: boolean) => void
}) => (
  <div
    onClick={() => {
      if (setHappyHour !== undefined) setHappyHour(false)
    }}
    className="happy-hour-indicator"
  >
    Within hour bottlenecks!
  </div>
)

const GraphSetting: FunctionComponent<GraphSettingProps> = observer((props) => {
  const { initializerStore, uiStore } = useStores()
  const { getAreas, getProductTypes } = initializerStore

  // Keep min-max state until we use it on graph
  const [minMax, setMinMax] = useState(props.minMax)

  const [settingsVisible, setSettingsVisible] = useState(false)

  // Props for loading a new graph
  const [date, setDate] = useState(moment())
  const [product, setProduct] = useState(0)
  const [products, setProducts] = useState<Contract[]>([])
  const [productType, setProductType] = useState<number>(props.productType)
  const [deliveryArea, setDeliveryArea] = useState<number>(props.area)

  const [indicatorsVisible, setIndicatorsVisible] = useState(false)

  const fetchProducts = () =>
    axiosInstance.post<Contract[]>(
      `/query/products/${dateFormat(date)}`,
      getProductTypes[productType]
    )

  useEffect(() => {
    fetchProducts().then((res) => setProducts(res.data))
  }, [])

  useEffect(() => {
    fetchProducts().then((res) => setProducts(res.data))
  }, [deliveryArea, productType])

  const loadNewGraph = () => {
    const query: TradeQuery = {
      area: getAreas[deliveryArea],
      contract: products[product],
    }
    props.setQuery(query)
  }

  const changeRange = (range: number) => {
    const newState = Object.assign({}, props.chartState)
    newState.range = range
    props.setChartState(newState)
  }

  const changeProduct = (product: number) => {
    props.setHappyHour && props.setHappyHour(false) // Changing product resets happy hour
    const newQuery = Object.assign({}, props.query)
    const newState = Object.assign({}, props.chartState)

    newState.product = product
    newQuery.contract = products.sort((a, b) => (a.name > b.name ? 1 : -1))[
      product
    ]

    props.setChartState(newState)
    props.setQuery(newQuery)
  }

  // Update state in parent
  const changeMinMax = () => props.setMinMax(minMax)

  return (
    <div className="graph-setting" ref={props.reference}>
      {/* These settings are shown even when the settings are not open. */}
      <div className="nav">
        {!settingsVisible && (
          <Fragment>
            <div className="area">{getAreas[props.area].name}</div>
            <IndexPicker
              selected={props.chartState.product}
              select={changeProduct}
              labels={products.map((p) => p.name).sort()}
            />
          </Fragment>
        )}
        <IndicatorButton fun={() => setIndicatorsVisible((value) => !value)} />
        <SettingsButton fun={() => setSettingsVisible((value) => !value)} />
        <ExitButton fun={props.exit} />
        {props.happyHour && (
          <HappyHourIndicator setHappyHour={props.setHappyHour} />
        )}
        {uiStore.direction && (
          <DirectionIndicator direction={props.direction} />
        )}

        {uiStore.lastTradeTimeIndicator && <LastTradeTimeIndicator lastTrade={props.lastTrade} />}
      </div>

      {/* Settings for graph one might load in place of current */}
      <div className={`inner ${settingsVisible ? 'visible' : 'hidden'}`}>
        <DatePicker value={date} setValue={setDate} />
        <Select
          label="Delivery area"
          options={getAreas.map((a) => a.name)}
          select={setDeliveryArea}
        />
        <Select
          label="Product type"
          selected={productType}
          options={getProductTypes.map((p) => p.name)}
          select={setProductType}
        />
        <Select
          label="Product"
          options={products.map((p) => p.name).sort()}
          select={setProduct}
        />
        <Button label="Show" onClick={loadNewGraph} />
      </div>

      {/* Settings for current graph */}
      <div className={`inner ${settingsVisible ? 'visible' : 'hidden'}`}>
        <DatePicker value={date} setValue={setDate} />
        <IndexPicker
          label="Product"
          selected={props.chartState.product}
          select={changeProduct}
          labels={products.map((p) => p.name).sort()}
        />
        <Select
          label="Range"
          options={ranges}
          selected={props.chartState.range}
          select={changeRange}
        />
        <Toggle
          on={minMax.use}
          toggle={() =>
            setMinMax((prev) => Object.assign({}, prev, { use: !prev.use }))
          }
          label={'MinMax'}
        />
        <Input
          label="Max"
          value={minMax.max}
          setValue={(n: number) =>
            setMinMax((prev) => Object.assign({}, prev, { max: n }))
          }
          enabled={minMax.use}
        />
        <Input
          label="Min"
          value={minMax.min}
          setValue={(n: number) =>
            setMinMax((prev) => Object.assign({}, prev, { min: n }))
          }
          enabled={minMax.use}
        />
        <Button label={'Submit'} onClick={changeMinMax} />
      </div>

      <IndicatorMenu
        visible={indicatorsVisible}
        indicators={props.indicators}
        update={props.setIndicators}
        tradeLine={props.tradeLine}
        toggleTradeLine={props.toggleTradeLine}
      />
    </div>
  )
})

type IndicatorMenuProps = {
  visible: boolean
  indicators: Indicator[]
  update: (indicator: Indicator[]) => void
  tradeLine: boolean
  toggleTradeLine: () => void
}

const IndicatorMenu: FunctionComponent<IndicatorMenuProps> = ({
  visible,
  indicators,
  update,
  tradeLine,
  toggleTradeLine,
}) => {
  // Color is reused across indicators
  const [color, setColor] = useState(0)
  const [indicator, setIndicator] = useState(0)

  // Window for the average for Simple Moving Average
  const [window, setWindow] = useState(0)

  // Whether to use number of trades or time for Volume Weighted Average Price
  const [useTrades, setUseTrades] = useState(true)
  const [trades, setTrades] = useState(0)
  const [hours, setHours] = useState(0)

  if (!visible) return <div />

  const addIndicator = () => {
    if (indicator === 0) {
      const sma: SMA = { window, color: colors[color], type: 'SMA' }
      // Check that we do not already have it - messes with keys in shown list
      if (
        indicators
          .map((i) => getIndicatorName(i))
          .indexOf(getIndicatorName(sma)) > -1
      ) {
        toast.error('Same indicator cannot be defined twice')
        return
      }
      update([...indicators, sma])
    } else {
      const vwap: VWAP = {
        useTrades,
        noOfTrades: trades,
        hours,
        color: colors[color],
        type: 'VWAP',
      }
      // Check that we do not already have it - messes with keys in shown list
      if (
        indicators
          .map((i) => getIndicatorName(i))
          .indexOf(getIndicatorName(vwap)) > -1
      ) {
        toast.error('Same indicator cannot be defined twice')
        return
      }
      update([...indicators, vwap])
    }
  }

  const removeIndicator = (index: number) => {
    const updated = []
    for (let i = 0; i < indicators.length; i++) {
      if (i !== index) updated.push(indicators[i])
    }
    update(updated)
  }

  return (
    <div>
      <div className="indicators visible">
        <Toggle on={tradeLine} toggle={toggleTradeLine} label={'Line'} />
        <Select
          label="Indicator"
          selected={indicator}
          options={indicatorOptions}
          select={setIndicator}
        />
        <ColorPicker value={color} f={setColor} />
        {/* SMA is chosen */}
        {indicator === 0 && (
          <Input
            enabled={true}
            value={window}
            label={'Window'}
            setValue={setWindow}
          />
        )}
        {/* VWAP is chosen */}
        {indicator === 1 && (
          <Fragment>
            <Toggle on={useTrades} toggle={setUseTrades} label={'Trades'} />
            {useTrades && (
              <Input
                enabled={true}
                label={'Trades'}
                value={trades}
                setValue={setTrades}
              />
            )}
            {!useTrades && (
              <Input
                enabled={true}
                label={'Hours'}
                value={hours}
                setValue={setHours}
              />
            )}
          </Fragment>
        )}
        <Button label="Add" onClick={addIndicator} />
      </div>
      <div
        className="indicators visible"
        style={{
          display: 'flex',
        }}
      >
        {indicators.map((indicator, index) => (
          <div
            key={getIndicatorName(indicator)}
            style={{
              display: 'flex',
              width: 'fit-content',
              padding: '1px 5px',
              borderRadius: '5px',
              fontSize: '10px',
              backgroundColor: indicator.color,
            }}
          >
            {getIndicatorName(indicator)}
            <span
              style={{ paddingLeft: '3px', cursor: 'pointer' }}
              onClick={() => removeIndicator(index)}
            >
              {' '}
              X
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default GraphSetting
