import { FunctionComponent } from 'react'
import './color-picker.scss'
import { colors } from '../../Util'

type ColorPickerProps = {
  value: number
  f: (color: number) => void
}

const ColorPicker: FunctionComponent<ColorPickerProps> = ({ value, f }) => {
  return (
    <label className="color-picker">
      {'Color'}
      <div className="color-picker-inner">
        {colors.map((c, idx) => (
          <div
            key={c}
            className={`color-option ${idx === value ? 'picked' : ''}`}
            style={{ backgroundColor: c }}
            onClick={() => f(idx)}
          />
        ))}
      </div>
    </label>
  )
}

export default ColorPicker
