import React, { ChangeEvent, FunctionComponent } from 'react'
import './input.scss'

type InputFieldProps = {
  enabled: boolean
  label: string
  // Optional title when hovering above label
  title?: string
  value?: number
  setValue: (n: number) => void
}
const Input: FunctionComponent<InputFieldProps> = ({
  enabled,
  label,
  title,
  value,
  setValue,
}) => {
  /** Validate and set value */
  const validate = (event: ChangeEvent<HTMLInputElement>) => {
    const asNumber = parseInt(event.target.value)
    setValue(asNumber)
  }

  return (
    <label className="input-field" title={title}>
      {label}
      <input
        type="tel"
        pattern="^-?[0-9]\d*\.?\d*$"
        value={value ? value : 0}
        disabled={!enabled}
        onChange={validate}
      />
    </label>
  )
}

export default Input
