import { useEffect, useState } from 'react'
import './header.scss'
import { IPublicClientApplication } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { useStores } from '../../useStores'
import { HAPPY_HOUR_EVENT, HappyHourCloseAllEvent } from '../Graph/ChartUtil'

const logout = (instance: IPublicClientApplication) => {
  instance.logoutRedirect().catch((e) => {
    console.log(e)
  })
}
const Header = () => {
  const { instance } = useMsal()
  const { uiStore } = useStores()

  const [happyHoursPresent, setHappyHoursPresent] = useState(false)

  function dispatchHappyHourCloseAllEvent() {
    document.dispatchEvent(new HappyHourCloseAllEvent())
    setHappyHoursPresent(false)
  }

  // Listen to happy hour events
  useEffect(() => {
    const callback = () => {
      setHappyHoursPresent(true)
    }

    document.addEventListener(HAPPY_HOUR_EVENT, callback)

    return () => {
      document.removeEventListener(HAPPY_HOUR_EVENT, callback)
    }
  }, [])

  return (
    <div className="header">
      {'BD Insight'}
      <div className="left"></div>
      <div className="right">
        {happyHoursPresent && (
          <div className='happy-hour-close' onClick={dispatchHappyHourCloseAllEvent}>
            Close All Bottleneck Alerts
          </div>
        )}
        <div onClick={() => uiStore.toggleSettings()}>Settings</div>
        <div className="logout" onClick={() => logout(instance)}>
          Log out
        </div>
      </div>
    </div>
  )
}

export default Header
