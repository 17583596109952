import React, { FunctionComponent } from 'react'
import './toggle.scss'

type ToggleProps = {
  on: boolean
  toggle: (b: boolean) => void
  label: string
}
const Toggle: FunctionComponent<ToggleProps> = ({ on, toggle, label }) => {
  return (
    <label className="toggle-field">
      {label}
      <div
        className={`toggle ${on ? 'on' : 'off'}`}
        onClick={() => toggle(!on)}
      >
        <div className={`switch ${on ? 'toggled' : ''}`} />
      </div>
    </label>
  )
}

export default Toggle
