import { type IMessage } from "@stomp/stompjs";
import { useStores } from "../useStores";
import { useEffect } from "react";

// Subscribe to a STOMP topic
export const useSubscribe = <T> (destination: string, callback: (t: T) => void, deps: React.DependencyList = []) => {
  const { socketStore } = useStores()

  useEffect(() => {
    if (socketStore.connected) {
      const callbackWrapper = (message: IMessage) => { callback(JSON.parse(message.body)) }
      const subscription = socketStore.socket.subscribe(destination, callbackWrapper)

      return () => { subscription.unsubscribe() }
    }
  }, [socketStore.socket, socketStore.connected, ...deps]);
}