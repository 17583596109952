import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import { MsalProvider } from '@azure/msal-react'
import { msalInstance } from './authConfig'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>
)
