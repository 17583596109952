import { createContext } from 'react'
import { SocketStore } from './SocketStore'
import { InitializerStore } from './InitializerStore'
import { ChartStore } from './ChartStore'
import { UIStore } from './UIStore'

export const rootStoreContext = createContext({
  uiStore: new UIStore(),
  socketStore: new SocketStore(),
  initializerStore: new InitializerStore(),
  chartStore: new ChartStore(),
})
