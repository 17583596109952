import { ranges } from '../../Util'
import { Trade } from '../../classes/Trade'
import { Indicator } from '../../classes/Indicator'
import { MinMaxProps, ZoomProps } from './Graph'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const d3 = require('d3')

const timeOptions = { hour: '2-digit', minute: '2-digit' }
const options = (seconds: boolean) =>
  (seconds
    ? { ...timeOptions, second: '2-digit' }
    : timeOptions) as Intl.DateTimeFormatOptions

/**
 * Format time HH:mm or HH:mm:ss
 * @param date the time to format
 * @param withSeconds should it include seconds
 */
export const formatTime = (date: Date, withSeconds = false) =>
  date.toLocaleTimeString('da-DK', options(withSeconds))

/** Return the tick interval for a given range */
const bottomTickInterval = (selectedRange: string) => {
  if (ranges.indexOf(selectedRange) < 2) return d3.timeMinute.every(5)
  else if (ranges.indexOf(selectedRange) < 3) return d3.timeMinute.every(10)
  else return d3.timeMinute.every(30)
}

const hour = 3600000
const filter = (rangeId: number, time: Date) => {
  // Try staying 5 seconds ahead of latest trade (to account for server time at EPEX)
  time.setSeconds(time.getSeconds() + 5)

  switch (rangeId) {
    case 0:
      return [new Date(time.valueOf() - hour), time]
    case 1:
      return [new Date(time.valueOf() - 2 * hour), time]
    case 2:
      return [new Date(time.valueOf() - 3 * hour), time]
    default:
      return [new Date(time.valueOf() - 24 * hour), time]
  }
}

const rangeFilter = (trades: Trade[], start: Date, end: Date) => {
  const [startMillis, endMillis] = [start.getTime(), end.getTime()]
  return trades.filter(
    (trade) =>
      startMillis < trade.time.getTime() && trade.time.getTime() < endMillis
  )
}

/**
 * Get Y (price) domain
 * @param zoom Zoom properties that overwrite everything else
 * @param minMax Min-max properties
 * @param trades Trades for full price range
 */
const getYDomain = (minMax: MinMaxProps, trades: Trade[], zoom?: ZoomProps) => {
  let minimum
  let maximum

  if (zoom) {
    // If user has zoomed, we use those
    minimum = zoom.y1
    maximum = zoom.y2
  } else if (minMax.use) {
    minimum = Math.min(minMax.min, minMax.max) * 100
    maximum = Math.max(minMax.min, minMax.max) * 100
  } else {
    // If user has set min and max, we use those, else we get range from data
    minimum = Math.min(...trades.map((t) => t.price))
    maximum = Math.max(...trades.map((t) => t.price))
  }

  return [minimum, maximum]
}

const indicatorOptions = ['SMA', 'VWAP']

interface LinePoint {
  x: Date
  y: number
}

type IndicatorLine = {
  name: string
  line: LinePoint[]
}

const getIndicatorName = (indicator: Indicator) => {
  switch (indicator.type) {
    case 'SMA':
      return `${indicator.type}-${indicator.window}`
    case 'VWAP':
      return indicator.useTrades
        ? `VWAP-${indicator.noOfTrades}`
        : `VWAP-${indicator.hours}`
  }
}

/**
 * Get indicator line.
 * @param trades list of all trades
 * @param indicator the indicator to create values for
 */
const getIndicator = (trades: Trade[], indicator: Indicator): IndicatorLine => {
  switch (indicator.type) {
    case 'SMA':
      const window = indicator.window
      const intervals = rolling(trades, window)
      const line: LinePoint[] = intervals.map((t) => ({
        x: t[t.length - 1].time,
        y: sum(t) / t.length,
      }))
      return { name: getIndicatorName(indicator), line }

    case 'VWAP':
      const { noOfTrades, hours } = indicator
      const minuteIntervals = getMinuteIntervals(trades, 5)

      if (indicator.useTrades && noOfTrades) {
        // In this case, take [no of trades] last trades before every time in intervals
        const line: LinePoint[] = []
        minuteIntervals.forEach((time) => {
          const index = trades.findIndex((trade) => trade.time > time)
          if (!(index - noOfTrades < 0)) {
            const withinInterval = trades.slice(
              index - (noOfTrades + 1),
              index - 1
            )
            const sum = withinInterval.reduce(
              (sum, trade) => sum + trade.quantity * trade.price,
              0
            )
            const volume = sumVolume(withinInterval)
            line.push({ x: time, y: sum / volume })
          }
        })

        return { name: getIndicatorName(indicator), line }
      } else if (hours) {
        const line: LinePoint[] = []

        minuteIntervals.forEach((time) => {
          const startingTime = new Date(time.getTime() - hours * hour)
          const first = trades.findIndex((trade) => trade.time > startingTime)
          const last = trades.findIndex((trade) => trade.time > time)

          if (first > -1 && last > -1) {
            const withinInterval = trades.slice(first, last)
            const sum = withinInterval.reduce(
              (sum, trade) => sum + trade.quantity * trade.price,
              0
            )
            const volume = sumVolume(withinInterval)
            line.push({ x: time, y: sum / volume })
          }
        })

        return { name: getIndicatorName(indicator), line }
      }
  }

  return { name: getIndicatorName(indicator), line: [] }
}

const getMinuteIntervals = (trades: Trade[], minutes: number) => {
  const minute = 1000 * 60
  const interval = minute * minutes
  const [start, end] = d3.extent(trades, (d: Trade) => d.time)
  const intervals = [
    new Date(Math.round(start.getTime() / interval) * interval),
  ]
  while (intervals[intervals.length - 1] < end) {
    intervals.push(
      new Date(intervals[intervals.length - 1].getTime() + interval)
    )
  }

  return intervals
}

/**
 * Return rolling window. Expects sorted list
 * @param trades list of trades
 * @param w window size
 */
const rolling = (trades: Trade[], w: number) => {
  const n = trades.length
  const result: Trade[][] = []

  if (n < w || w <= 0) {
    return result
  }

  for (let i = 0; i < n - w + 1; i++) {
    result.push(trades.slice(i, w + i))
  }

  return result
}

const sum = (trades: Trade[]) =>
  trades.reduce((sum, trade) => sum + trade.price, 0)
const sumVolume = (trades: Trade[]) =>
  trades.reduce((sum, trade) => sum + trade.quantity, 0)

export {
  bottomTickInterval,
  filter,
  getYDomain,
  indicatorOptions,
  rangeFilter,
  getIndicator,
  getIndicatorName,
}
export type { LinePoint }

export const HAPPY_HOUR_CLOSE_ALL_EVENT = 'HAPPY_HOUR_CLOSE_ALL_EVENT'
export class HappyHourCloseAllEvent extends Event {
  constructor() {
    super(HAPPY_HOUR_CLOSE_ALL_EVENT)
  }
}

export const HAPPY_HOUR_EVENT = 'HAPPY_HOUR_EVENT'
export class HappyHourEvent extends Event {
  constructor() {
    super(HAPPY_HOUR_EVENT)
  }
}
