import { useEffect, useRef, useState } from 'react'
import './initializer.scss'
import DatePicker from '../DatePicker/DatePicker'
import Select from '../Select/Select'
import Button from '../Button/Button'
import { ChartInfo } from '../../classes/ChartInfo'
import { observer } from 'mobx-react'
import { useStores } from '../../useStores'
import { dateFormat, getDeliveryStart, getTimeZone, ranges } from '../../Util'
import { Area, Contract } from '../../classes/QueryOptions'
import { toast } from 'react-toastify'
import axiosInstance from '../../axios'
import moment, { Moment } from 'moment-timezone'

type TrackingEntry = {
  date: Moment
  area: Area
  areaIndex: number
  productType: number
}

// Product along with its index
type IndexedProduct = { product: Contract; index: number }

const Initializer = observer(() => {
  const { initializerStore, chartStore, uiStore } = useStores()
  const { addChart, modifyChart, removeChart } = chartStore
  const { trackingOffset } = uiStore

  // The contracts we track, the info we need to update next day + the setInterval we have running
  const tracking = useRef<ChartInfo[]>([])
  const trackingEntry = useRef<TrackingEntry | undefined>(undefined)
  const trackingTimeout = useRef<NodeJS.Timer | undefined>(undefined)

  const {
    getDate,
    getAreas,
    getProductTypes,
    getProducts,
    getProduct,
    getArea,
    getProductType,
    tradeQuery,
  } = initializerStore
  const { setDate, setArea, setProductType, setProduct } = initializerStore

  const [range, setRange] = useState(1)

  // Make sure we clear interval on unmount
  useEffect(() => {
    return () => clearInterval(trackingTimeout.current)
  }, [])

  // If tracking offset is updated, update the callback function
  useEffect(() => {
    if (trackingTimeout.current) {
      clearInterval(trackingTimeout.current)
      trackingTimeout.current = setInterval(() => updateTracking(), 1000)
    }
  }, [trackingOffset]);

  /** Create a list of charts */
  const makeCharts = (
    date: Moment,
    area: Area,
    areaIndex: number,
    productType: number,
    contracts: Contract[]
  ) => {
    const timeZone = getTimeZone(area.queryId)
    const now = moment()
    const seedId = now.valueOf()

    const products = contracts
      .map((product, i) => {
        return { product, index: i } as IndexedProduct
      })
      .filter(
        (elem: IndexedProduct) =>
          getDeliveryStart(elem.product, timeZone).valueOf() - trackingOffset > seedId
      )

    return products.map((elem, idx) => {
      return {
        timeRequested: seedId + idx,
        date: date,
        tradeQuery: { area, contract: elem.product },
        range: idx === 0 ? uiStore.firstHourRange : 1,
        area: areaIndex,
        product: elem.index,
        productType: getProductType,
      } as ChartInfo
    })
  }

  // Submit chart invocation to parent
  const submit = () => {
    const timeRequested = moment().valueOf()
    const date = getDate
    const area = getArea
    const product = getProduct
    const productType = getProductType

    const chart: ChartInfo = {
      timeRequested,
      date,
      tradeQuery,
      range,
      area,
      product,
      productType,
    }

    addChart(chart)
  }

  /** Create charts for next day */
  const addNextDay = (te: TrackingEntry) => {
    const tomorrow = te.date.add(1, 'days')
    axiosInstance
      .post<Contract[]>(
        `/query/products/${dateFormat(tomorrow)}`,
        getProductTypes[te.productType]
      )
      .then((response) => {
        const contracts = response.data as Contract[]
        const sorted = contracts.sort((a, b) => (a.name > b.name ? 1 : -1))
        const newCharts = makeCharts(
          te.date,
          te.area,
          te.areaIndex,
          te.productType,
          sorted
        )

        // Save new charts here, update tracking entry and add charts to UI
        trackingEntry.current = Object.assign({}, te, { date: tomorrow })
        tracking.current = [...tracking.current, ...newCharts]
        newCharts.forEach(addChart)
      })
  }

  // This function checks if we should update the charts
  const updateTracking = () => {
    const tracked = tracking.current
    if (tracked) {
      const minimumId = tracked.reduce((prev, curr) =>
        prev.timeRequested < curr.timeRequested ? prev : curr
      )
      const timeZone = getTimeZone(minimumId.tradeQuery.area.queryId)

      // Check that it is time to replace this
      const diff =
        getDeliveryStart(minimumId.tradeQuery.contract, timeZone).valueOf() -
        moment().valueOf()

      // If diff is higher than offset, we do not do anything
      if (diff > trackingOffset) return

      const updatedList = tracked.filter(
        (c) => c.timeRequested !== minimumId.timeRequested
      )

      // Remove chart where delivery has started
      removeChart(minimumId.timeRequested)
      tracking.current = [
        ...tracked.filter((t) => t.timeRequested !== minimumId.timeRequested),
      ]

      // If there are still tracked hours left, modify the new minimum to have range = 1 hour
      if (updatedList.length > 0) {
        const newMin = updatedList.reduce((prev, curr) =>
          prev.timeRequested < curr.timeRequested ? prev : curr
        )
        modifyChart(newMin.timeRequested, { range: uiStore.firstHourRange })

        // If there are only 9 contracts, we can the ones for tomorrow
        const te = trackingEntry.current
        if (te && updatedList.length < 9) addNextDay(te)
      } else {
        // We are done tracking now
        clearInterval(trackingTimeout.current)
        trackingTimeout.current = undefined
      }
    }
  }

  // Submit several charts
  const submitTracking = () => {
    // Check if we are currently tracking charts
    const tracked = tracking.current
    if (tracked) {
      const ids = tracked.map((c) => c.timeRequested)
      const stillTracking = chartStore.charts.some(
        (c) => ids.findIndex((id) => id === c.timeRequested) > -1
      )
      if (stillTracking) {
        toast.error('Tracking is already defined.')
        return
      }
    }

    trackingEntry.current = {
      date: getDate,
      area: getAreas[getArea],
      areaIndex: getArea,
      productType: getProductType,
    }

    const newCharts = makeCharts(
      getDate,
      getAreas[getArea],
      getArea,
      getProductType,
      getProducts
    )

    tracking.current = newCharts
    newCharts.forEach(addChart)

    trackingTimeout.current = setInterval(() => updateTracking(), 1000)
  }

  return (
    <div className="initializer">
      <DatePicker value={getDate} setValue={setDate} />
      <Select
        label="Delivery area"
        options={getAreas.map((a) => a.name)}
        select={setArea}
      />
      <Select
        label="Product type"
        options={getProductTypes.map((p) => p.name)}
        select={setProductType}
      />
      <Select
        label="Product"
        options={getProducts.slice().map((p) => p.name)}
        select={setProduct}
      />
      <Select
        selected={range}
        label="Range"
        options={ranges}
        select={setRange}
      />
      <div className="buttons">
        <Button label={'Tracking'} onClick={submitTracking} />
        <Button label="Trading chart" onClick={submit} />
      </div>
    </div>
  )
})

export default Initializer
