import React, { FunctionComponent } from 'react'
import './select.scss'

type SelectProps = {
  label: string
  options: string[]
  select: (selection: number) => void
  selected?: number
}

const Select: FunctionComponent<SelectProps> = ({
  label,
  options,
  select,
  selected,
}) => {
  // The first one is where we do not set the selected item from outside
  if (selected === undefined) {
    return (
      <label key={label} className="select">
        {label}
        <select onChange={(event) => select(parseInt(event.target.value, 10))}>
          {options.map((option, index) => (
            <option value={index} key={option}>
              {option}
            </option>
          ))}
        </select>
      </label>
    )
  } else {
    return (
      <label key={label} className="select">
        {label}
        <select
          value={selected}
          onChange={(event) => select(parseInt(event.target.value, 10))}
        >
          {options.map((option, index) => (
            <option value={index} key={option}>
              {option}
            </option>
          ))}
        </select>
      </label>
    )
  }
}

export default Select
