import React, { FunctionComponent } from 'react'
import './button.scss'

type ButtonProps = {
  label: string
  onClick: () => void
}

const Button: FunctionComponent<ButtonProps> = ({ label, onClick }) => {
  return (
    <div className="button-group">
      <div className="filler"></div>
      <div className="button" onClick={onClick}>
        <p>{label}</p>
      </div>
    </div>
  )
}

export default Button
