import { makeAutoObservable, reaction } from 'mobx'
import axiosInstance from '../axios'
import {
  Area,
  Contract,
  ProductType,
  TradeQuery,
} from '../classes/QueryOptions'
import moment, { Moment } from 'moment'
import { dateFormat } from '../Util'
import { toast } from 'react-toastify'

/** Sorting for areas - start with Market areas */
const sortArea = (a: Area, b: Area) => {
  if (a.type === 'Market' && b.type !== 'Market') return -1
  else if (a.type !== 'Market' && b.type === 'Market') return 1
  else return a.name > b.name ? 1 : -1
}

/**
 * Contains the options for creating a new graph.
 */
export class InitializerStore {
  // Set to true when we have token for API
  private authenticated = false

  // Options
  private areas: Area[] = []
  private productTypes: ProductType[] = []
  private products: Contract[] = []

  // Picked items
  private date: Moment = moment()
  private area = 0
  private productType = 0
  private product = 0

  constructor() {
    makeAutoObservable(this)

    reaction(
      () => this.authenticated,
      (status, prevStatus) => {
        if (status && !prevStatus) {
          axiosInstance
            .get<ProductType[]>('/query/product-types')
            .then((response) => {
              this.productTypes = response.data as ProductType[]
            })

          axiosInstance.get<Area[]>('/query/areas').then((response) => {
            this.areas = response.data as Area[]
          })
        }
      }
    )

    // Should only run once when the data has been set by the constructor
    reaction(
      () => [this.areas, this.productTypes],
      ([areas, productTypes]) => {
        if (areas.length > 0 && productTypes.length > 0)
          this.fetchProducts().then((response) => {
            this.products = response.data as Contract[]
          })
      }
    )

    reaction(
      () => [this.productType, this.area],
      ([productType, area], [prevProductType, prevArea]) => {
        if (productType !== prevProductType || area !== prevArea)
          this.fetchProducts().then((response) => {
            this.products = response.data as Contract[]
          })
      }
    )
  }

  public setAuthenticated = (authenticated: boolean) => {
    this.authenticated = authenticated
  }

  public get getDate() {
    return this.date
  }

  public get getAreas() {
    return this.areas.slice().sort(sortArea)
  }

  public get getProductTypes() {
    return this.productTypes
  }

  public get getProducts() {
    return this.products.slice().sort((a, b) => (a.name > b.name ? 1 : -1))
  }

  public get tradeQuery(): TradeQuery {
    return {
      area: this.getAreas[this.area],
      contract: this.products
        .slice()
        .sort((a, b) => (a.name > b.name ? 1 : -1))[this.product],
    }
  }

  public setDate = (date: Moment) => {
    this.fetchProducts().then((response) => {
      const products = response.data as Contract[]
      if (products.length !== 0) {
        // If there were 0 products, this means we don't have contracts for date
        this.products = products
        this.date = date
      } else toast.error('No contracts available for date')
    })
  }

  public get getArea() {
    return this.area
  }

  public setArea = (index: number) => {
    this.area = index
  }

  public get getProductType() {
    return this.productType
  }

  public setProductType = (index: number) => {
    this.productType = index
  }

  public get getProduct() {
    return this.product
  }

  public setProduct = (index: number) => {
    this.product = index
  }

  /**
   * Fetch contracts from backend based on date and product type.
   */
  private fetchProducts = () =>
    axiosInstance.post<Contract[]>(
      `/query/products/${dateFormat(this.date)}`,
      this.productTypes[this.productType]
    )
}
