import { PublicClientApplication } from '@azure/msal-browser'

const msalConfig = {
  auth: {
    clientId: '95bc98d7-388d-482e-b3fa-2ddfce4adc19',
    authority:
      'https://login.microsoftonline.com/e5a29949-bfa7-4681-b21b-b1b0a986dab0',
    redirectUri: process.env.REACT_APP_AUTH_REDIRECT,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
}

export const scopes = [
  'api://19fb26ff-d80e-4ca0-b952-8c0d2eaaf707/Insight.Read',
]

export const msalInstance = new PublicClientApplication(msalConfig)

export const getToken = async () => {
  const account = msalInstance.getAllAccounts()[0]
  if (account) {
    const accessTokenResponse = await msalInstance.acquireTokenSilent({
      scopes: scopes,
      account: account,
    })
    if (accessTokenResponse && accessTokenResponse.accessToken) {
      return accessTokenResponse.accessToken
    } else {
      return null // No access token
    }
  } else {
    return null // No account
  }
}
