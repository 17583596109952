import { observer } from 'mobx-react'
import { useStores } from '../../useStores'
import Graph from '../Graph/Graph'

const ChartHolder = observer(() => {
  const { chartStore, uiStore } = useStores()
  const { charts } = chartStore
  const { columnized, rows } = uiStore

  // Sort first rows by column
  if (columnized && charts.length > 1) {
    // Actual number of rows to sort
    const actual = Math.floor(Math.min(charts.length, 2 * rows) / 2)

    // The order we take the first charts.
    // We do this based on list instead of <Fragment> with two charts,
    // as this forces update for all charts as keys are not aligned with actual charts shown
    const indices = [...Array(actual).keys()].flatMap((num) => [
      num,
      num + actual,
    ])

    return (
      <div className="graph-list-holder">
        {indices.map((index) => (
          <Graph
            key={charts[index].timeRequested}
            id={charts[index].timeRequested}
            exit={chartStore.removeChart}
            initialData={charts[index]}
          />
        ))}
        {charts.slice(2 * actual).map((chart) => (
          <Graph
            key={chart.timeRequested}
            id={chart.timeRequested}
            exit={chartStore.removeChart}
            initialData={chart}
          />
        ))}
      </div>
    )
  }

  // Standard row based view
  return (
    <div className="graph-list-holder">
      {charts.map((chart) => (
        <Graph
          key={chart.timeRequested}
          id={chart.timeRequested}
          exit={chartStore.removeChart}
          initialData={chart}
        />
      ))}
    </div>
  )
})

export default ChartHolder
