import React, { Fragment, FunctionComponent, useState } from 'react'
import './settings.scss'
import { observer } from 'mobx-react'
import { useStores } from '../../useStores'
import { Indicator, SMA, VWAP } from '../../classes/Indicator'
import { colors, ranges } from '../../Util'
import { getIndicatorName, indicatorOptions } from '../Graph/ChartUtil'
import { toast } from 'react-toastify'
import Toggle from '../Toggle/Toggle'
import Select from '../Select/Select'
import ColorPicker from '../ColorPicker/ColorPicker'
import Input from '../Input/Input'
import Button from '../Button/Button'

const Settings = observer(() => {
  const { uiStore, chartStore } = useStores()

  return (
    <div className="settings">
      <p className="settings-title">Theme</p>
      <div className="theme-picker">
        <div className="theme">
          Light
          <div
            onClick={() => uiStore.setTheme('light')}
            className="color light"
          />
        </div>
        <div className="theme">
          Dark
          <div
            onClick={() => uiStore.setTheme('dark')}
            className="color dark"
          />
        </div>
      </div>
      <p className="settings-title">View</p>
      <div className="view">
        <Toggle
          on={uiStore.columnized}
          toggle={uiStore.toggleColumnized}
          label={'Columns'}
        />
        <Input
          enabled={uiStore.columnized}
          label={'Rows'}
          value={uiStore.rows}
          setValue={uiStore.setRows}
        />
      </div>
      <div className="view">
        <Toggle
          on={uiStore.direction}
          toggle={uiStore.toggleDirection}
          label={'Direction'}
        />
      </div>
      <div className="view">
        <Toggle
          on={uiStore.lastTradeTimeIndicator}
          toggle={uiStore.toggleLastTradeTimeIndicator}
          label={'Trade Time'}
        />
      </div>
      <p className="settings-title">Tracking</p>
      <div className="view">
        <Select
          selected={uiStore.firstHourRange}
          label="First hour range"
          options={ranges}
          select={uiStore.setTrackingRange}
        />
      </div>
      <div className="view">
        <Input
          enabled={true}
          label={'Tracking offset (mins)'}
          title={'Specifies no of minutes before delivery a chart is removed'}
          value={uiStore.trackingOffset / 60000}
          setValue={num => uiStore.setTrackingOffest(num * 60000)}
        />
      </div>
      <p className="settings-title">Bottleneck Indicator</p>
      <div className="view">
        <Toggle
          on={uiStore.happyHourIndicator}
          toggle={uiStore.toggleHappyHourIndicator}
          label={'Enabled'}
        />
        <Input
          enabled={uiStore.happyHourIndicator}
          label={'Threshold'}
          value={uiStore.happyHourThreshold / 100}
          setValue={uiStore.setHappyHourThreshold}
        />
      </div>
      <p className="settings-title">Default indicators</p>
      <IndicatorMenu
        indicators={chartStore.indicators}
        add={chartStore.addIndicator}
        remove={chartStore.removeIndicator}
      />
    </div>
  )
})

type IndicatorMenuProps = {
  indicators: Indicator[]
  add: (indicator: Indicator) => void
  remove: (id: string) => void
}

const IndicatorMenu: FunctionComponent<IndicatorMenuProps> = ({
  indicators,
  add,
  remove,
}) => {
  // Color is reused across indicators
  const [color, setColor] = useState(0)
  const [indicator, setIndicator] = useState(0)

  // Window for the average for Simple Moving Average
  const [window, setWindow] = useState(0)

  // Whether to use number of trades or time for Volume Weighted Average Price
  const [useTrades, setUseTrades] = useState(true)
  const [trades, setTrades] = useState(0)
  const [hours, setHours] = useState(0)

  const addIndicator = () => {
    if (indicator === 0) {
      const sma: SMA = { window, color: colors[color], type: 'SMA' }
      // Check that we do not already have it - messes with keys in shown list
      if (
        indicators
          .map((i) => getIndicatorName(i))
          .indexOf(getIndicatorName(sma)) > -1
      ) {
        toast.error('Same indicator cannot be defined twice')
        return
      }
      add(sma)
    } else {
      const vwap: VWAP = {
        useTrades,
        noOfTrades: trades,
        hours,
        color: colors[color],
        type: 'VWAP',
      }
      // Check that we do not already have it - messes with keys in shown list
      if (
        indicators
          .map((i) => getIndicatorName(i))
          .indexOf(getIndicatorName(vwap)) > -1
      ) {
        toast.error('Same indicator cannot be defined twice')
        return
      }
      add(vwap)
    }
  }

  return (
    <Fragment>
      <div className="indicators">
        <div className="row">
          <Select
            label="Indicator"
            selected={indicator}
            options={indicatorOptions}
            select={setIndicator}
          />
          <ColorPicker value={color} f={setColor} />
        </div>
        <div className="row">
          {/* SMA is chosen */}
          {indicator === 0 && (
            <Input
              enabled={true}
              value={window}
              label={'Window'}
              setValue={setWindow}
            />
          )}
          {/* VWAP is chosen */}
          {indicator === 1 && (
            <Fragment>
              <Toggle on={useTrades} toggle={setUseTrades} label={'Trades'} />
              {useTrades && (
                <Input
                  enabled={true}
                  label={'Trades'}
                  value={trades}
                  setValue={setTrades}
                />
              )}
              {!useTrades && (
                <Input
                  enabled={true}
                  label={'Hours'}
                  value={hours}
                  setValue={setHours}
                />
              )}
            </Fragment>
          )}
          <Button label="Add" onClick={addIndicator} />
        </div>
      </div>
      <div className="list">
        {indicators.map((indicator) => {
          const name = getIndicatorName(indicator)
          return (
            <div
              className="indicator"
              key={name}
              style={{ backgroundColor: indicator.color }}
            >
              {name}
              <span onClick={() => remove(name)}> X</span>
            </div>
          )
        })}
      </div>
    </Fragment>
  )
}

export default Settings
