import axios from 'axios'
import { msalInstance, scopes } from './authConfig'

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URI}/api`,
})

axiosInstance.interceptors.request.use(
  async (config) => {
    const account = msalInstance.getAllAccounts()[0]
    if (account) {
      const accessTokenResponse = await msalInstance.acquireTokenSilent({
        scopes: scopes,
        account: account,
      })

      if (accessTokenResponse) {
        const accessToken = accessTokenResponse.accessToken
        if (config.headers && accessToken) {
          config.headers['Authorization'] = `Bearer ${accessToken}`
        }
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default axiosInstance
