import React, { FunctionComponent } from 'react'
import './index-picker.scss'

type IndexPickerProps = {
  label?: string
  selected: number
  select: (n: number) => void
  labels: string[]
}

/**
 * Component for picking the index at -1 or +1 with arrows
 * @param label Label above selector
 * @param selected The selected index
 * @param select Function for selecting
 * @param labels Indexed labels to pick from
 */
const IndexPicker: FunctionComponent<IndexPickerProps> = ({
  label,
  selected,
  select,
  labels,
}) => {
  const doSelect = (n: number) => {
    if (selected + n >= labels.length) select(0)
    else if (selected + n < 0) select(labels.length - 1)
    else select(selected + n)
  }

  return (
    <label className="index-picker">
      {label}
      <div className="index-picker-body">
        <div className="input-button" onClick={() => doSelect(-1)}>
          <p>&#8249;</p> {/* Left arrow */}
        </div>
        <div className="selected-field">
          <p className="selected-field-label">{labels[selected]}</p>
        </div>
        <div className="input-button" onClick={() => doSelect(1)}>
          <p>&#8250;</p> {/* Right arrow */}
        </div>
      </div>
    </label>
  )
}

export default IndexPicker
