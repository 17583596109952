import { ChartInfo } from '../classes/ChartInfo'
import { makeAutoObservable } from 'mobx'
import { Indicator } from '../classes/Indicator'
import { getIndicatorName } from '../components/Graph/ChartUtil'

const INDICATORS = 'INDICATORS'

export class ChartStore {
  private chartInfos: ChartInfo[] = []
  private defaultIndicators: Indicator[] = []

  constructor() {
    makeAutoObservable(this)

    const indicatorsFromStorage = localStorage.getItem(INDICATORS)
    if (indicatorsFromStorage !== null) {
      this.defaultIndicators = JSON.parse(indicatorsFromStorage)
    }
  }

  public get indicators() {
    return this.defaultIndicators
  }

  public get charts() {
    return this.chartInfos
      .slice()
      .sort((a, b) => (a.timeRequested > b.timeRequested ? 1 : -1))
  }

  public modifyChart = (id: number, overwrites: object) => {
    const chart = this.chartInfos.find((chart) => chart.timeRequested === id)
    if (chart) {
      const updated = Object.assign({}, chart, overwrites)
      this.chartInfos = [
        ...this.chartInfos.filter((c) => c.timeRequested !== id),
        updated,
      ]
    }
  }

  public removeChart = (id: number) => {
    this.chartInfos = this.chartInfos.filter(
      (chart) => chart.timeRequested !== id
    )
  }

  public addChart = (chartInfo: ChartInfo) => {
    this.chartInfos.push(chartInfo)
  }

  public addIndicator = (indicator: Indicator) => {
    this.defaultIndicators = [...this.defaultIndicators, indicator]
    localStorage.setItem(INDICATORS, JSON.stringify(this.defaultIndicators))
  }

  public removeIndicator = (id: string) => {
    this.defaultIndicators = this.defaultIndicators.filter(
      (i) => getIndicatorName(i) !== id
    )
    localStorage.setItem(INDICATORS, JSON.stringify(this.defaultIndicators))
  }
}
