export interface ProductType {
  name: string
  duration: number
}

export interface Area {
  queryId: string
  name: string
  type: string
}

export interface Contract {
  date: string
  name: string
  duration: number
  localDeliveryStart: string
}

export interface TradeQuery {
  area: Area
  contract: Contract
}

// Get normalize contract id for subscribing top trade topic
export const normalize = (query: TradeQuery) =>
  query.contract.date + query.contract.name.replaceAll(/([-:])/gm, '')
