import React, { FunctionComponent } from 'react'
import './datepicker.scss'
import moment, { Moment } from 'moment'

type DatePickerProps = {
  value: Moment
  setValue: (d: Moment) => void
}

const DatePicker: FunctionComponent<DatePickerProps> = ({
  value,
  setValue,
}) => {
  return (
    <div className="datepicker">
      <label>Delivery Date</label>
      <div className="date-input">
        <input
          type="date"
          value={value.format('YYYY-MM-DD')}
          onChange={(e) => setValue(moment(e.target.value))}
        />
        <div className="date-input-buttons">
          <div
            className="date-input-button"
            onClick={() => setValue(moment(value.add(-1, 'days')))}
          >
            <p>&#8249;</p> {/* Left arrow */}
          </div>
          <div
            className="date-input-button"
            onClick={() => setValue(moment(value.add(1, 'days')))}
          >
            <p>&#8250;</p> {/* Right arrow */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DatePicker
