import { makeAutoObservable } from 'mobx'

const THEME = 'THEME'
const COLUMNIZED = 'COLUMNIZED'
const ROWS = 'ROWS'
const DIRECTION = 'DIRECTION'
const FIRST_HOUR_RANGE = 'FH_RANGE'
const TRACKING_OFFSET = 'TRACKING_OFFSET'
const HAPPY_HOUR_INDICATOR = 'HAPPY_HOUR_INDICATOR'
const HAPPY_HOUR_THRESHOLD = 'HAPPY_HOUR_THRESHOLD'
const LAST_TRADE_TIME_INDICATOR = 'LAST_TRADE_TIME_INDICATOR'

export class UIStore {
  public theme = localStorage.getItem(THEME)
  public settingsVisible = false
  public columnized = false
  public rows = 3
  public direction = false
  public happyHourIndicator = true
  public happyHourThreshold = 1000
  // How long before delivery start do we delete the newest chart
  public trackingOffset = 300_000 // 5 minutes is default
  public lastTradeTimeIndicator = false
  public firstHourRange = localStorage.getItem(FIRST_HOUR_RANGE) === undefined ? 0
    : Number(localStorage.getItem(FIRST_HOUR_RANGE))

  constructor() {
    makeAutoObservable(this)

    // Set storage variables if there are any
    const colStorage = localStorage.getItem(COLUMNIZED)
    if (colStorage) {
      this.columnized = JSON.parse(colStorage)
    }
    const rowStorage = localStorage.getItem(ROWS)
    if (rowStorage) {
      this.rows = JSON.parse(rowStorage)
    }
    const dirStorage = localStorage.getItem(DIRECTION)
    if (dirStorage) {
      this.direction = JSON.parse(dirStorage)
    }
    const happyHourIndicatorStorage = localStorage.getItem(HAPPY_HOUR_INDICATOR)
    if (happyHourIndicatorStorage) {
      this.happyHourIndicator = JSON.parse(happyHourIndicatorStorage)
    }
    const happyHourThresholdStorage = localStorage.getItem(HAPPY_HOUR_THRESHOLD)
    if (happyHourThresholdStorage) {
      this.happyHourThreshold = JSON.parse(happyHourThresholdStorage)
    }

    const lastTradeTimeIndicatorStorage = localStorage.getItem(LAST_TRADE_TIME_INDICATOR)
    if (lastTradeTimeIndicatorStorage) {
      this.lastTradeTimeIndicator = JSON.parse(lastTradeTimeIndicatorStorage)
    }

    const trackingOffset = Number(localStorage.getItem(TRACKING_OFFSET))
    if (trackingOffset) {
      this.trackingOffset = trackingOffset
    }
  }

  setTheme = (theme: string) => {
    this.theme = theme
    localStorage.setItem(THEME, theme)
  }

  toggleSettings = () => {
    this.settingsVisible = !this.settingsVisible
  }

  toggleColumnized = () => {
    this.columnized = !this.columnized
    localStorage.setItem(COLUMNIZED, String(this.columnized))
  }

  setRows = (rows: number) => {
    if (isNaN(rows)) {
      this.rows = 0
    } else {
      this.rows = rows
    }
    localStorage.setItem(ROWS, String(this.rows))
  }

  // Set range for next closing hour in tracking mode.
  setTrackingRange = (range: number) => {
    this.firstHourRange = range
    localStorage.setItem(FIRST_HOUR_RANGE, String(range))
  }

  setTrackingOffest = (offset: number) => {
    this.trackingOffset = offset
    localStorage.setItem(TRACKING_OFFSET, String(offset))
  }

  toggleDirection = () => {
    this.direction = !this.direction
    localStorage.setItem(DIRECTION, String(this.direction))
  }

  toggleHappyHourIndicator = () => {
    this.happyHourIndicator = !this.happyHourIndicator
    localStorage.setItem(HAPPY_HOUR_INDICATOR, String(this.happyHourIndicator))
  }

  setHappyHourThreshold = (threshold: number) => {
    if (isNaN(threshold)) {
      this.happyHourThreshold = 0
    } else {
      this.happyHourThreshold = threshold * 100
    }
    localStorage.setItem(HAPPY_HOUR_THRESHOLD, String(this.happyHourThreshold))
  }

  toggleLastTradeTimeIndicator = () => {
    this.lastTradeTimeIndicator = !this.lastTradeTimeIndicator
    localStorage.setItem(LAST_TRADE_TIME_INDICATOR, String(this.lastTradeTimeIndicator))
  }
}
