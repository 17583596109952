import { makeAutoObservable, reaction } from 'mobx'
import { Stomp } from '@stomp/stompjs'
import { toast } from 'react-toastify'

export class SocketStore {
  private connectionString = process.env.REACT_APP_WS_URI
    ? process.env.REACT_APP_WS_URI
    : ''
  public socket = Stomp.over(() => new WebSocket(this.connectionString))
  public connected = false
  public token: string | null = null

  constructor() {
    makeAutoObservable(this)
    this.socket.debug = () => {}

    reaction(
      () => this.token,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (token, prev) => {
        if (this.connected) return
        if (token !== null)
          this.socket.connect(
            { Authorization: `Bearer ${token}` },
            this.onConnected,
            this.onError,
            this.onClose
          )
      }
    )
  }

  onError = () => {
    /* Do nothing */
  }

  onClose = () => {
    this.connected = false
    toast.error('Connection to backend lost, refresh window.', {
      autoClose: false,
    })
  }

  onConnected = () => {
    this.connected = true
  }

  setToken = (token: string | null) => {
    this.token = token
  }
}
