import { Moment } from 'moment'
import { Contract } from './classes/QueryOptions'
import moment from 'moment-timezone'
/*
Store everything with options and such in here
 */

/** Format date for API */
export const dateFormat = (moment: Moment) => moment.format('YYYYMMDD')

// Colors for indicators
export const colors = ['#37D67A', '#FF8A65', '#BA68C8', '#0693E3', '#F47373']

// Graph ranges
export const ranges = ['One hour', 'Two hours', 'Three hours', 'Full period']

export const getDeliveryStart = (contract: Contract, timeZone: string) => {
  const dateString = contract.localDeliveryStart
  return moment.tz(dateString, 'YYYYMMDD HH:mm:ss', timeZone).toDate()
}

export const getTimeZone = (queryId: string) => {
  switch (queryId) {
    case '10YGB----------A':
      return 'Europe/London'
    default:
      return 'Europe/Berlin'
  }
}
