import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { useStores } from '../../useStores'
import { ChartInfo } from '../../classes/ChartInfo'
import './graph.scss'
import { Trade } from '../../classes/Trade'
import {
  bottomTickInterval,
  filter,
  formatTime,
  getIndicator,
  getYDomain,
  HAPPY_HOUR_CLOSE_ALL_EVENT,
  HappyHourEvent,
  LinePoint,
  rangeFilter,
} from './ChartUtil'
import { ranges } from '../../Util'
import axiosInstance from '../../axios'
import GraphSetting, { Direction } from '../GraphSetting/GraphSetting'
import Tooltip, { TooltipProps } from './Tooltip/Tooltip'
import { Indicator } from '../../classes/Indicator'
import { Contract, normalize } from '../../classes/QueryOptions'
import moment from 'moment'
import { toast } from 'react-toastify'
import { useSubscribe } from "../../hooks/useSubscribe";
import { useResizeObserver } from "../../hooks/useResizeObserver";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const d3 = require('d3')

const HEIGHT = 330 // Height of the chart
const PERCENTAGE_WIDTH = 0.94 // How wide is chart compared to parent, change this in CSS as well
const VERTICAL_MARGIN_PERCENTAGE = 0.1 // Add 10 percent of space above and below graph content
const VERTICAL_MARGIN = HEIGHT * VERTICAL_MARGIN_PERCENTAGE // Actual top and bottom margin number
const X_TICK_HEIGHT = 32 // Height of tick on x-axis in pixels
const GRAPH_HORIZONTAL_MARGIN = 0.02 // Trades cannot be in the first or last 2 percent of chart
const MIN_HAPPY_HOUR_TRADES = 5 // Minimum number of trades to determine happy hour

// const SETTINGS_PANE_HEIGHT = 66
// const TOOLTIP_OFFSET_Y = SETTINGS_PANE_HEIGHT

/** Get start of local market for power hour */
const getLocalMarketTime = (contract: Contract) => {
  const dateString = contract.date + contract.name.split('-')[0]
  return moment(dateString, 'YYYYMMDDHH:mm').add(-30, 'minutes').toDate()
}

const getClosedTradingTime = (contract: Contract) => {
  const dateString = contract.date + contract.name.split('-')[0]
  return moment(dateString, 'YYYYMMDDHH:mm').add(-1, 'hours').toDate()
}

/**
 * Return true if bottlenecks should be ignored currently.
 * This is usually because the bottlenecks are already handled by our algortihms and should not disturb the traders.
 */
const inHappyHourIgnorePeriod = (): boolean => {
  // Ignore if between **:00:05 - **:00:15
  const now = new Date()

  const start = new Date(now)
  start.setMinutes(0)
  start.setSeconds(5)

  const end = new Date(now)
  end.setMinutes(0)
  end.setSeconds(15)

  if (now > start && now < end) return true

  return false
}

/**
 * The range should be the PERCENTAGE_WIDTH middle of the parent object
 * @return array with two values; [start, end] of range
 */
const xRange = (parentWidth: number) => [
  (parentWidth * (1 - PERCENTAGE_WIDTH)) / 2,
  parentWidth - (parentWidth * (1 - PERCENTAGE_WIDTH)) / 2,
]

type GraphProps = {
  /** Identifier */
  id: number
  /** Reference to a function that deletes this graph */
  exit: (id: number) => void
  /** Initial data */
  initialData: ChartInfo
}

export type ZoomProps = {
  x1: Date
  x2: Date
  y1: number
  y2: number
}

export type MinMaxProps = {
  use: boolean // Has min-max been enabled
  min: number
  max: number
}

/**
 * This component is not the actual d3 code, that is placed in D3Chart.ts in this folder.
 * This just manages the state around the d3 code.
 */
const Graph: FunctionComponent<GraphProps> = observer(
  ({ id, exit, initialData }) => {
    // References to this div and to svg containing graph
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const svgReference = useRef<any>()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const settingsRef = useRef<any>()

    const { chartStore, uiStore } = useStores()
    const { happyHourIndicator, happyHourThreshold } = uiStore

    const [chartState, setChartState] = useState(initialData)
    const [query, setQuery] = useState(initialData.tradeQuery)

    const [tradeLine, toggleTradeLine] = useState(true)
    const [indicators, setIndicators] = useState<Indicator[]>(
      chartStore.indicators
    )

    const [width, setWidth] = useState(733)

    const [allTrades, setTrades] = useState<Trade[]>([])

    // User set minimum and maximum values, plus if we should use them or not
    const [minMax, setMinMax] = useState({ use: false, min: 0, max: 0 })
    const [zoom, setZoom] = useState<ZoomProps | undefined>(undefined)

    const [tooltip, setTooltip] = useState<TooltipProps>({
      show: false,
      x: 0,
      y: 0,
      trade: undefined,
    })

    const [happyHour, setHappyHour] = useState(false)
    const [lastHappyHourTrade, setLastHappyHourTrade] = useState<Trade | null>(
      null
    )

    // Use resize observer to get the size of this component
    const resizeRef = useResizeObserver(entry => {
      setWidth(() => entry.contentBoxSize[0].inlineSize)
    })

    /**
     * Notify the use if a bottleneck might be occuring (a so called "happy hour" as Nicolaj calls it)
     * @param sortedTrades List of all trades sort by time
     */
    const determineHappyHour = (sortedTrades: Trade[]) => {
      if (!happyHourIndicator) return // Happy hour indicator is disabled
      if (happyHour) return // Happy hour alert already shown, do not show again
      if (sortedTrades.length < MIN_HAPPY_HOUR_TRADES + 1) return // Not enough trades to determine happy hour
      if (inHappyHourIgnorePeriod()) return // Ignore happy hour if we are in a period where we do not want to disturb the traders

      // Get when trading closes
      const closedTradingTime = getClosedTradingTime(query.contract)
      if (Date.now() >= closedTradingTime.getTime()) return // Do not trigger happy hour if we are are not in the local market

      // Get the last MIN_HAPPY_HOUR_TRADES trades (excluding the last one)
      const lastTrades = sortedTrades.slice(
        sortedTrades.length - MIN_HAPPY_HOUR_TRADES - 1,
        sortedTrades.length - 1
      )

      // Get the average price of the last trades
      const averagePrice =
        lastTrades.reduce((sum, trade) => sum + trade.price, 0) /
        lastTrades.length

      // Get the last trade
      const lastTrade = sortedTrades[sortedTrades.length - 1]

      if (lastHappyHourTrade?.id === lastTrade.id) return // Last trade was already a happy hour trade

      // If the last trade is more/less than HAPPY_HOUR_THRESHOLD above/below the average, we have a happy hour
      const diff = Math.abs(lastTrade.price - averagePrice)
      if (diff < happyHourThreshold) {
        // No happy hour
        return
      }

      // Happy hour
      setHappyHour(true)
      setLastHappyHourTrade(lastTrade)
      document.dispatchEvent(new HappyHourEvent())

      // Get the bottleneck area
      const bottleneckArea =
        lastTrade.price < averagePrice ? lastTrade.sellArea : lastTrade.buyArea

      toast.success(
        <>
          {`Bottleneck: ${query.area.name} ${query.contract.name}`}
          <br />
          {`Area: ${bottleneckArea}`}
          <br />
          {`Price Delta: ${(diff / 100).toFixed(2)} EUR`}
        </>,
        { autoClose: 30000 }
      )
    }

    // Listen to happy hour close all events
    useEffect(() => {
      const callback = () => {
        setHappyHour(false)
      }

      document.addEventListener(HAPPY_HOUR_CLOSE_ALL_EVENT, callback)

      return () => {
        document.removeEventListener(HAPPY_HOUR_CLOSE_ALL_EVENT, callback)
      }
    }, [])

    // React to range change in props
    useEffect(() => {
      if (initialData.range !== chartState.range) {
        const updated = Object.assign({}, chartState, {
          range: initialData.range,
        })
        setChartState(updated)
      }
    }, [initialData.range])

    useSubscribe(`/topic/trades/${query.area.queryId}/${normalize(query)}`, (trade: Trade) => {
      trade.time = new Date(trade.time)
      setTrades((trades) => [...trades.filter((t) => t.id !== trade.id), trade])
    }, [query])

    useEffect(() => {
      const controller = new AbortController()

      axiosInstance
        .post<Trade[]>('/trades/', query, { signal: controller.signal })
        .then((response) => {
          const trades: Trade[] = response.data as Trade[]
          trades.forEach((trade) => (trade.time = new Date(trade.time)))
          setTrades(trades)
        })

      return () => controller.abort()
    }, [query])

    const sortedTrades = allTrades.sort((a, b) => (a.time > b.time ? 1 : -1))
    const lastTrade = sortedTrades[sortedTrades.length - 1]

    useEffect(() => {
      determineHappyHour(sortedTrades)
    }, [allTrades])

    useEffect(() => {
      if (allTrades.length === 0) return

      const range = chartState.range
      const visibleRange = ranges[range]
      const chart = d3.select(svgReference.current)

      const indicatorG = chart.select('#indicator-g-' + id)
      indicatorG.select('*').remove()

      const [startTime, endTime] = zoom
        ? [zoom.x1, zoom.x2]
        : filter(range, new Date())
      // If it is full period, do not filter the trades at all
      const trades =
        zoom || range < 3
          ? rangeFilter(allTrades, startTime, endTime)
          : allTrades

      const [minimum, maximum] = getYDomain(minMax, trades, zoom)

      // Pixel range
      const [startRange, endRange] = xRange(width)
      const rangeLength = endRange - startRange

      const xScale = d3
        .scaleTime()
        .domain(
          range < 3
            ? [startTime, endTime]
            : d3.extent(trades, (d: Trade) => d.time)
        ) // Last case is full trading period
        .range([
          startRange + rangeLength * GRAPH_HORIZONTAL_MARGIN,
          endRange - rangeLength * GRAPH_HORIZONTAL_MARGIN,
        ])

      const yScale = d3
        .scaleLinear()
        .domain([minimum, maximum])
        .range([HEIGHT - VERTICAL_MARGIN, VERTICAL_MARGIN])
        .nice() // Round the numbers a bit

      const lineGenerator = d3
        .line()
        .x((d: Trade) => xScale(d.time))
        .y((d: Trade) => yScale(d.price))

      const indicatorGenerator = d3
        .line()
        .x((p: LinePoint) => xScale(p.x))
        .y((p: LinePoint) => yScale(p.y))

      const indicatorGeneratorCurveStepAfter = d3
        .line()
        .x((p: LinePoint) => xScale(p.x))
        .y((p: LinePoint) => yScale(p.y))
        .curve(d3.curveStepBefore)

      const tickXAxisInterval = zoom ? [5] : bottomTickInterval(visibleRange)
      const bottomTicks = d3
        .axisBottom()
        .scale(xScale)
        .ticks(tickXAxisInterval)
        .tickSize(5)
        .tickFormat((date: Date) => formatTime(date))

      const priceTicks = d3
        .axisRight()
        .scale(yScale)
        .ticks([5])
        .tickSize(0)
        .tickSizeInner(-(endRange - startRange))
        .tickFormat((tick: number) => tick / 100)

      indicators.forEach((indicator) => {
        const { name, line } = getIndicator(allTrades, indicator)

        const generator =
          indicator.type === 'SMA'
            ? indicatorGenerator
            : indicatorGeneratorCurveStepAfter

        indicatorG
          .append('path')
          .datum(line.sort((a, b) => (a.x > b.x ? 1 : -1)))
          .join()
          .attr('id', `${name}-${id}`)
          .attr('d', generator)
          .style('stroke', indicator.color)
          .style('stroke-width', '1px')
          .style('fill', 'none')
      })

      chart
        .select('#tick-bottom-' + id)
        .attr('transform', `translate(0,${HEIGHT})`)
        .call(bottomTicks)
        .selectAll('text')
        .attr('class', 'tick-text')
        .attr('transform', 'translate(-15,12) rotate(-55)')

      chart
        .select('#tick-right-' + id)
        .style('translate', `${endRange}px 0`)
        .call(priceTicks)

      if (tradeLine) {
        chart
          .select('#trade-line-path-' + id)
          .style('opacity', 1)
          .datum(sortedTrades) // Important to set here
          .attr('d', lineGenerator)
      } else {
        chart.select('#trade-line-path-' + id).style('opacity', 0)
      }

      const dragHandler = d3
        .drag()
        .on('start', (d: MouseEvent) => {
          chart
            .append('rect')
            .attr('id', 'drag-' + id)
            .style('fill', 'red')
            .style('opacity', 0.1)
            .attr('x', d.x)
            .attr('y', d.y)
        })
        .on('drag', (d: MouseEvent) => {
          const rect = chart.select('#drag-' + id)
          const boundingBox = rect.node().getBBox()
          const [xMin, xMax] =
            boundingBox.x < d.x ? [boundingBox.x, d.x] : [d.x, boundingBox.x]
          const [yMin, yMax] =
            boundingBox.y < d.y ? [boundingBox.y, d.y] : [d.y, boundingBox.y]
          const width = xMax - xMin
          const height = yMax - yMin
          rect
            .attr('x', xMin)
            .attr('y', yMin)
            .attr('width', width)
            .attr('height', height)
            .attr(
              'transform',
              `translate(0, -${settingsRef.current.clientHeight})`
            )
        })
        .on('end', () => {
          const bBox = chart
            .select('#drag-' + id)
            .node()
            .getBBox()
          // Make sure it was not just a click
          if (bBox.width > 150 && bBox.height > 150) {
            const zoomX = [
              xScale.invert(bBox.x),
              xScale.invert(bBox.x + bBox.width),
            ]
            const zoomY = [
              // For y, account for height of settings right above graph.
              yScale.invert(
                bBox.y + bBox.height - settingsRef.current.clientHeight
              ),
              yScale.invert(bBox.y - settingsRef.current.clientHeight),
            ]
            setZoom({ x1: zoomX[0], x2: zoomX[1], y1: zoomY[0], y2: zoomY[1] })
          }
          chart.select('#drag-' + id).remove()
        })

      dragHandler(chart)

      const removeTooltip = () => {
        const t = Object.assign({}, tooltip)
        t.show = false
        setTooltip(t)
      }

      const markerCircle = chart.select('#highlight-' + id)

      markerCircle
        .attr('cx', 0)
        .attr('cy', 0)
        .attr('r', 7.5)
        .style('fill', 'grey')
        .style('opacity', 0)
        .on('mouseleave', function () {
          markerCircle.style('opacity', 0).attr('cy', -100)
          removeTooltip()
        })

      // Add listener to chart so it deletes tooltip if mouse is moved out of graph
      chart.on('mouseleave', function () {
        markerCircle.style('opacity', 0).attr('cy', -100)
        removeTooltip()
      })

      const showTooltip = (d: MouseEvent, trade: Trade) => {
        let [x, y] = [xScale(trade.time), yScale(trade.price)]

        markerCircle
          .attr('cx', `${x}px`)
          .attr('cy', `${y}px`)
          .style('opacity', 0.2)

        // Only tooltip needs adjusting, not markerCircle
        if (x + 220 > width) {
          x = x - 220
        }

        if (y + 100 > HEIGHT) {
          y = y - 100
        }

        setTooltip({
          show: true,
          x,
          y: y + settingsRef.current.clientHeight,
          trade,
        })
      }

      // Set the local market rect, only for power hour
      if (query.contract.duration === 1) {
        const localMarketStart = getLocalMarketTime(query.contract)
        const scaled = xScale(localMarketStart)

        // Check that this is within graph, else do not draw as we get minus width
        if (scaled <= endRange) {
          chart
            .select('#local-market-' + id)
            .attr('x', scaled)
            .attr('width', endRange - scaled)
        }
      }

      const circles = d3.select('#trades-g-' + id).selectAll('circle')

      circles
        .data(trades)
        .join('circle')
        .attr('cx', (d: Trade) => xScale(d.time))
        .attr('cy', (d: Trade) => yScale(d.price))
        .attr('r', 1.5)

      circles.on('mousemove', showTooltip)

      return () => {
        indicatorG.selectAll('path').remove()
        chart.select('#local-market-' + id).attr('width', 0) // Set local market area to width 0
      }
    }, [width, allTrades, zoom, tradeLine, chartState, indicators, minMax])

    let direction
    if (sortedTrades.length > 1) {
      const [t1, t2] = [
        sortedTrades[sortedTrades.length - 1].price,
        sortedTrades[sortedTrades.length - 2].price,
      ]
      if (t1 === t2) direction = { direction: Direction.NEU, price: t1 }
      else if (t1 > t2) direction = { direction: Direction.UP, price: t1 }
      else direction = { direction: Direction.DOWN, price: t1 }
    }

    return (
      <div ref={resizeRef} className="chart-holder">
        <GraphSetting
          reference={settingsRef}
          exit={() => exit(id)}
          chartState={chartState}
          setChartState={setChartState}
          query={query}
          setQuery={setQuery}
          product={chartState.product}
          productType={chartState.productType}
          area={chartState.area}
          minMax={minMax}
          setMinMax={setMinMax}
          indicators={indicators}
          setIndicators={setIndicators}
          tradeLine={tradeLine}
          toggleTradeLine={() => toggleTradeLine((value) => !value)}
          direction={direction}
          happyHour={happyHour}
          setHappyHour={setHappyHour}
          lastTrade={lastTrade}
        />
        <svg
          className="chart"
          id={'svg-' + id}
          ref={svgReference}
          height={`${HEIGHT + X_TICK_HEIGHT}px`}
        >
          <rect
            className="background"
            height={`${HEIGHT}px`}
            width={`${PERCENTAGE_WIDTH * 100}%`}
            style={{
              translate: `${(1 - PERCENTAGE_WIDTH) * 50}% 0`,
            }}
          />
          <rect id={'local-market-' + id} className="local-market-rect" />

          <g>
            <path id={'trade-line-path-' + id} className="trade-line" />
          </g>
          <g>
            <circle id={'highlight-' + id} />
          </g>
          <g id={'trades-g-' + id} />
          <g id={'indicator-g-' + id} />
          {/* These 3 rectangles cover the edges of the graph, so no data goes outside 'background' rect */}
          <rect
            className={'cutoff left'}
            x={0}
            width={`${(1 - PERCENTAGE_WIDTH) * 50}%`}
          />
          <rect
            className={'cutoff right'}
            x={`calc(100% - ${(1 - PERCENTAGE_WIDTH) * 50}%)`}
            width={`${(1 - PERCENTAGE_WIDTH) * 50}%`}
          />
          <rect
            className={'cutoff bottom'}
            y={`calc(100% - ${X_TICK_HEIGHT}px`}
            height={X_TICK_HEIGHT}
          />
          <g id={'tick-right-' + id} className="tick right" />
          <g id={'tick-bottom-' + id} className="tick bottom" />
        </svg>
        <p
          className="reset-zoom"
          onClick={() => setZoom(undefined)}
          title={'Reset zoom'}
          style={{
            opacity: zoom ? '1' : '0',
            top: settingsRef.current
              ? `calc(${settingsRef.current.clientHeight}px + 1px)`
              : '30px',
            right: `calc(${(1 - PERCENTAGE_WIDTH) * 50}% + 1px)`,
          }}
        >
          &#9740;
        </p>
        <Tooltip {...tooltip} />
      </div>
    )
  }
)

export default Graph
