import React, { useEffect } from 'react'
import './App.scss'
import Header from './components/Header/Header'
import Initializer from './components/Initializer/Initializer'
import Settings from './components/Settings/Settings'
import ChartHolder from './components/ChartHolder/ChartHolder'
import { observer } from 'mobx-react'
import { useStores } from './useStores'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  AuthenticatedTemplate,
  useIsAuthenticated,
  useMsalAuthentication,
} from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import { getToken } from './authConfig'

const App = observer(() => {
  const { socketStore, uiStore, initializerStore } = useStores()
  const { theme, settingsVisible } = uiStore

  useMsalAuthentication(InteractionType.Redirect)
  const isAuthenticated = useIsAuthenticated()

  useEffect(() => {
    if (isAuthenticated) {
      getToken().then((token) => {
        socketStore.setToken(token)
        initializerStore.setAuthenticated(true)
      })
    }
  }, [isAuthenticated])

  useEffect(() => {
    const handleKeyPress = (key: KeyboardEvent) => {
      // Drop chosen schema and variables on 'Escape' keydown event
      if (key.altKey && key.code === 'KeyM') {
        uiStore.setTheme('matrix')
      }
    }
    window.addEventListener('keydown', handleKeyPress)

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [])

  return (
    <div className="App" data-theme={theme ? theme : 'light'}>
      <AuthenticatedTemplate>
        <Header />
        <ToastContainer />
        <Initializer />
        <ChartHolder />
        {settingsVisible && <Settings />}
      </AuthenticatedTemplate>
    </div>
  )
})

export default App
